/**
 * SEO component that queries for data with Gatsby's useStaticQuery React hook.
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function combineMeta(meta, defaults) {
  let defKey, hasDefault, settingKey

  defaults.forEach(def => {
    defKey = Object.keys(def)[0]

    hasDefault = meta.some(setting => {
      settingKey = Object.keys(setting)[0]
      return def[defKey] === setting[settingKey]
    })

    if (!hasDefault) {
      meta.push(def)
    }
  })

  return meta
}

function SEO({ description, lang, meta, title, schema, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description.trim() || site.siteMetadata.description

  // Add `og:image` dimensions.
  const hasOgImage = meta.some(metaSetting => {
    return metaSetting.property === 'og:image'
  })

  if (hasOgImage) {
    meta.push({
      property: 'og:image:width',
      content: 1200,
    })

    meta.push({
      property: 'og:image:height',
      content: 630,
    })
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={combineMeta(meta, [
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: 'https://www.chrisdpeters.com/images/social/summary-avatar.png',
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ])}
    >
      {(() => {
        if (schema) {
          return (
            <script type="application/ld+json">
              {JSON.stringify(schema)}
            </script>
          )
        }
      })()}

      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default SEO
