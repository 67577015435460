import React from 'react'

const LogoMark = () => (
  <figure className="logo">
    <span className="logo-dark">C</span>
    <span className="logo-accent">P</span>
  </figure>
)

export default LogoMark
