import React from 'react'

const Section = (props) => {
  const { children } = props

  return (
    <section className="section" { ...props }>
      {children}
    </section>
  )
}

export default Section
