import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import GlobalNav from './global-nav'

const Header = ({ currentSection }) => (
  <header className="header">
    <Container>
      <Row className="align-items-end">
        <Col md={4} lg={5} className="header-logo">
          <Link to="/" className="logo">
            Chris{' '}
            <span className="logo-accent">
              Peters
            </span>
          </Link>
        </Col>

        <Col md={8} lg={7}>
          <GlobalNav currentSection={currentSection} />
        </Col>
      </Row>
    </Container>
  </header>
)

Header.propTypes = {
  currentSection: PropTypes.string,
}

export default Header
