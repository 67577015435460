import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const GlobalNav = ({ currentSection }) => {
  const sectionClasses = {
    'about': '',
    'portfolio': '',
    'playbook': '',
    'blog': '',
  }

  sectionClasses[currentSection] = 'global-nav-active'

  return (
    <nav className="global-nav">
      <Link
        to="/"
        className={['global-nav-link', sectionClasses.about].join(' ')}
      >
        About Me
      </Link>

      <Link
        to="/portfolio/"
        className={['global-nav-link', sectionClasses.portfolio].join(' ')}
      >
        My Work
      </Link>

      <Link
        to="/playbook/"
        className={['global-nav-link', sectionClasses.playbook].join(' ')}
      >
        Playbook
      </Link>

      <Link
        to="/blog/"
        className={['global-nav-link', sectionClasses.blog].join(' ')}
      >
        Blog
      </Link>
    </nav>
  )
}

GlobalNav.propTypes = {
  currentSection: PropTypes.string,
}

GlobalNav.defaultProps = {
  currentSection: 'about',
}

export default GlobalNav
