/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'

const Layout = ({ error404, children, currentSection }) => {
  const mainProps = {}

  if (error404) {
    mainProps['data-error-404'] = true
  }

  return (
    <div className="layout">
      <Header currentSection={currentSection} />

      <main {...mainProps}>
        {children}
      </main>

      <Footer />
    </div>
  )
}

Layout.defaultProps = {
  error404: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  error404: PropTypes.bool,
  currentSection: PropTypes.string,
}

export default Layout
